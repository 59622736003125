import { z } from 'zod';
import { Climatiq } from '../../../../climatiq';
import {
  type UserEditableDataVersioningMetadata,
  makeUpdateSchema,
} from '../../user-editable-data';

export interface TransportVersion extends UserEditableDataVersioningMetadata {
  note: string | null;

  year: number;
  origin: string;
  destination: string;
  transportMode: Climatiq.TransportMode;
  cargoWeightKg: number;

  computedDistanceKm: number | null;
  ghgFootprintKgCo2e: number | null;

  sourceTrail: Climatiq.SourceTrail | null;
}

export const TransportVersionInputSchema = z.object({
  year: Climatiq.YearSchema,
  origin: z.string(),
  destination: z.string(),
  cargoWeightKg: z.coerce.number().gt(0),
  transportMode: Climatiq.TransportModeSchema,
  note: z.string().optional().nullable(),
});
export type TransportVersionInput = z.infer<typeof TransportVersionInputSchema>;

export const TransportVersionUpdateSchema = makeUpdateSchema(
  TransportVersionInputSchema,
);
export type TransportVersionUpdate = z.infer<
  typeof TransportVersionUpdateSchema
>;
