'use client';
import { XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import type React from 'react';
import { type ReactNode, useEffect } from 'react';

interface ModalProps {
  onClose?: () => void;
  children: React.ReactNode;
  bgClassName?: string;
  containerClassName?: string;
  hideTopBar?: boolean;
  topBarContent?: ReactNode;
  title?: string;
  customContainer?: boolean;
}

export default function Modal({
  onClose,
  hideTopBar,
  children,
  title,
  bgClassName,
  topBarContent,
  containerClassName,
  customContainer,
}: ModalProps) {
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose?.();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  useEffect(() => {
    // Disable body scrolling
    document.body.style.overflow = 'hidden';

    return () => {
      // Re-enable scrolling when modal unmounts
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div
      className={classNames(
        'fixed w-full top-0 right-0 bottom-0 pb-4 z-40 flex flex-col overflow-auto',
        bgClassName,
      )}
    >
      {!hideTopBar && (
        <div className="fixed h-16 px-10 border-b border-gray-200 z-50 w-full bg-white flex flex-row">
          <div className="flex flex-row items-center w-full">
            <button type="button" className="absolute" onClick={onClose}>
              <XMarkIcon className="h-5 w-5 text-gray-600" />
            </button>
            <div className="w-full flex justify-center items-center py-4 font-medium text-sm">
              {topBarContent || title}
            </div>
          </div>
        </div>
      )}
      {customContainer ? (
        children
      ) : (
        <div
          className={classNames(
            'flex flex-row justify-center w-full flex-grow',
            !hideTopBar && 'mt-16',
          )}
        >
          <div className={classNames(containerClassName, 'w-full')}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
}
