import { z } from 'zod';
import { Climatiq } from '../../../../climatiq';
import {
  type UserEditableDataVersioningMetadata,
  makeUpdateSchema,
} from '../../user-editable-data';

export const FacilityEnergyBillElectricityType = 'electricity_grid';
export type FacilityEnergyBillElectricityType =
  typeof FacilityEnergyBillElectricityType;

export type FacilityEnergyBillType =
  | FacilityEnergyBillElectricityType
  | Climatiq.FuelCombustionFuelType;

export const FacilityEnergyBillTypeSchema = z.union([
  z.literal(FacilityEnergyBillElectricityType),
  ...(Object.values(Climatiq.FuelCombustionFuelType).map((type) =>
    z.literal(type),
  ) as [
    z.ZodLiteral<Climatiq.FuelCombustionFuelType>,
    ...z.ZodLiteral<Climatiq.FuelCombustionFuelType>[],
  ]),
]);

type FuelUnitTypeMapping = {
  energy: typeof Climatiq.UnitSchemas.EnergySchema._type.energy_unit;
  volume: typeof Climatiq.UnitSchemas.VolumeSchema._type.volume_unit;
  weight: typeof Climatiq.UnitSchemas.WeightSchema._type.weight_unit;
};
//
// Utility type to get the unit types for a given fuel type
type UnitTypesForFuel<T extends Climatiq.FuelCombustionFuelType> =
  FuelUnitTypeMapping[(typeof Climatiq.SupportedCombustionFuelUnitsByFuelType)[T][number]];

export type FacilityEnergyBillConsumptionUnit<
  T extends FacilityEnergyBillType,
> = T extends FacilityEnergyBillElectricityType
  ? FuelUnitTypeMapping['energy']
  : T extends Climatiq.FuelCombustionFuelType
    ? UnitTypesForFuel<T>
    : never;
export const FacilityEnergyBillConsumptionUnitSchema = z.union([
  Climatiq.UnitSchemas.EnergySchema.shape.energy_unit,
  Climatiq.UnitSchemas.VolumeSchema.shape.volume_unit,
  Climatiq.UnitSchemas.WeightSchema.shape.weight_unit,
]);

export interface FacilityEnergyBillVersion<
  T extends FacilityEnergyBillType = FacilityEnergyBillType,
> extends UserEditableDataVersioningMetadata {
  parentFacilityId: string;
  note: string | null;

  activeStartDate: Date;
  activeEndDate: Date;

  type: T;
  consumption: number;
  consumptionUnit: FacilityEnergyBillConsumptionUnit<T>;
  electricityRenewableShare: number | null;

  marketBasedGhgFootprintKgCo2e: number | null;
  locationBasedGhgFootprintKgCo2e: number | null;
  upstreamEmissionsKgCo2e: number | null;

  marketBasedSourceTrail: Climatiq.SourceTrail | null;
  locationBasedSourceTrail: Climatiq.SourceTrail | null;
}

export const FacilityEnergyBillVersionInputSchema = z.object({
  parentFacilityId: z.string().uuid(),

  activeStartDate: z.coerce.date(),
  activeEndDate: z.coerce.date(),

  type: FacilityEnergyBillTypeSchema,
  consumption: z.coerce.number().positive(),
  consumptionUnit: FacilityEnergyBillConsumptionUnitSchema,
  electricityRenewableShare: z.coerce
    .number()
    .gte(0)
    .lte(1)
    .optional()
    .nullable(),
  note: z.string().optional().nullable(),
});
export type FacilityEnergyBillVersionInput = z.infer<
  typeof FacilityEnergyBillVersionInputSchema
>;

export const FacilityEnergyBillVersionUpdateSchema = makeUpdateSchema(
  FacilityEnergyBillVersionInputSchema,
);
export type FacilityEnergyBillVersionUpdate = z.infer<
  typeof FacilityEnergyBillVersionUpdateSchema
>;
