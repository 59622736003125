import { z } from 'zod';

export enum PossibleObjectKindEnum {
  BusinessTravel = 'BusinessTravel',
  Commute = 'Commute',
  Expense = 'Expense',
  Facility = 'Facility',
  FacilityEnergyBill = 'FacilityEnergyBill',
  Transport = 'Transport',
}

export type PossibleObjectKind = keyof typeof PossibleObjectKindEnum;
export const PossibleObjectKindSchema = z.nativeEnum(PossibleObjectKindEnum);

export interface CorporateBulkImportJobInfo {
  bulkImportId: string;
}

export type CorporateBulkImportSseMessage =
  | {
      type: 'progress';
      data: number | { error: string };
    }
  | {
      type: 'failed' | 'completed';
      data: string | null;
    };

export interface CorporateBulkImportResults {
  inputObjectsCount: number;
  successfullyImportedCount: number;
  errors: Array<{ serializedObjectData: string; importError: string }>;
}

export interface CorporateBulkImport {
  id: string;
  kind: PossibleObjectKind;
  createdAt: Date;
  createdByUserName: string | null;
  totalObjectsCount: number;
  processedAt: Date | null;
  succeededCount: number;
  failedCount: number;
}
export const CorporateBulkImportSchema = z.object({
  id: z.string().uuid(),
  kind: PossibleObjectKindSchema,
  createdAt: z.coerce.date(),
  createdByUserName: z.string().nullable(),
  totalObjectsCount: z.number().nonnegative(),
  processedAt: z.coerce.date().nullable(),
  succeededCount: z.number().nonnegative(),
  failedCount: z.number().nonnegative(),
});
