import { z } from 'zod';
// Shared types for user-editable data features
// See ADR: https://www.notion.so/Writing-data-from-Platform-19ff2ec7a9c080ba9e4efe66b75dc530

/**
 * Base set of fields we expect ALL compatible Prisma models to have.
 *
 * Note that valid Prisma models must extend *exactly* this subset, and not loosely.
 * Notably, `undefined` doesn't exist in Prismaland, NULL should be used instead in the TypeScript defs here.
 */
export interface UserEditableDataVersioningMetadata {
  accountSlug: string;
  brandId: string | null;

  objectId: string; // ID of the object
  versionId: string; // ID of the version

  createdAt: Date;
  createdBy: number | null;

  deletedAt: Date | null;
  deletedBy: number | null;
}

// Minimum metadata needed to update an existing object
export interface UserEditableDataMinimumUpdateMetadata {
  objectId: string;
  versionId: string;
}

// helpers to make larger schemas from the actual object data
export function makeUpdateSchema<T extends z.ZodRawShape>(
  inputSchema: z.ZodObject<T>,
) {
  return inputSchema.extend({
    objectId: z.string().uuid(),
    versionId: z.string().uuid(),
  });
}
