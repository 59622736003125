import { z } from 'zod';
import { Climatiq } from '../../../../climatiq';
import {
  type UserEditableDataVersioningMetadata,
  makeUpdateSchema,
} from '../../user-editable-data';

export interface CommuteVersion extends UserEditableDataVersioningMetadata {
  note: string | null;

  year: number;
  facilityId: string;
  nbEmployees: number;
  daysInAttendance: number;
  avgHomeDistanceKm: number;
  travelMode: Climatiq.TravelMode;

  computedFacilityLocation: string | null;
  ghgFootprintKgCo2e: number | null;

  sourceTrail: Climatiq.SourceTrail | null;
}

export const CommuteVersionInputSchema = z.object({
  facilityId: z.string().uuid(),
  year: Climatiq.YearSchema,
  nbEmployees: z.coerce.number().int().positive(),
  daysInAttendance: z.coerce.number().int().positive().gt(0).lte(365),
  avgHomeDistanceKm: z.coerce.number().positive(),
  travelMode: Climatiq.TravelModeSchema,
  note: z.string().optional().nullable(),
});
export type CommuteVersionInput = z.infer<typeof CommuteVersionInputSchema>;

export const CommuteVersionUpdateSchema = makeUpdateSchema(
  CommuteVersionInputSchema,
);
export type CommuteVersionUpdate = z.infer<typeof CommuteVersionUpdateSchema>;
