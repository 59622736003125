import { z } from 'zod';
import { Climatiq } from '../../../../climatiq';
import {
  type UserEditableDataVersioningMetadata,
  makeUpdateSchema,
} from '../../user-editable-data';

export enum FacilityType {
  Office = 'OFFICE',
  Store = 'STORE',
  Factory = 'FACTORY',
  Warehouse = 'WAREHOUSE',
}
export const FacilityTypeSchema = z.nativeEnum(FacilityType);

export enum FacilityOwnership {
  Owned = 'OWNED',
  Leased = 'LEASED',
}
export const FacilityOwnershipSchema = z.nativeEnum(FacilityOwnership);

export const CountryOrCountryAndRegionSchema = z.enum([
  ...Climatiq.CountryCodeSchema._def.values,
  ...Climatiq.CountryWithRegionCodeSchema._def.values,
]);
export type CountryOrCountryAndRegion = z.infer<
  typeof CountryOrCountryAndRegionSchema
>;

export interface FacilityVersion extends UserEditableDataVersioningMetadata {
  note: string | null;

  name: string;
  type: FacilityType;
  locationAddress: string | null;
  locationCountryOrRegion: CountryOrCountryAndRegion;
  areaSqMeters: number;
  ownership: FacilityOwnership;

  lastYearFootprintKgCo2e: number | null;
}

export const FacilityVersionInputSchema = z.object({
  name: z.string(),
  type: z.nativeEnum(FacilityType),
  locationAddress: z.string().optional().nullable(),
  locationCountryOrRegion: CountryOrCountryAndRegionSchema,
  areaSqMeters: z.coerce.number().int().positive(),
  ownership: z.nativeEnum(FacilityOwnership),
  note: z.string().optional().nullable(),
});
export type FacilityVersionInput = z.infer<typeof FacilityVersionInputSchema>;

export const FacilityVersionUpdateSchema = makeUpdateSchema(
  FacilityVersionInputSchema,
);
export type FacilityVersionUpdate = z.infer<typeof FacilityVersionUpdateSchema>;
