import { z } from 'zod';
import { Climatiq } from '../../../../climatiq';
import {
  type UserEditableDataVersioningMetadata,
  makeUpdateSchema,
} from '../../user-editable-data';

export interface BusinessTravelVersion
  extends UserEditableDataVersioningMetadata {
  note: string | null;

  year: number;
  origin: string;
  destination: string;
  participants: number;
  travelMode: Climatiq.TravelMode;
  hotelStay: boolean;
  roundTrip: boolean;

  hotelNights: number | null;
  hotelLocation: string | null;

  computedDistanceKm: number | null;
  ghgFootprintKgCo2e: number | null;

  sourceTrail: Climatiq.SourceTrail | null;
}

export const BusinessTravelVersionInputSchema = z.object({
  year: Climatiq.YearSchema,
  origin: z.string(),
  destination: z.string(),
  participants: z.coerce.number().int().positive(),
  travelMode: Climatiq.TravelModeSchema,
  roundTrip: z.coerce.boolean(),
  hotelStay: z.coerce.boolean(),
  hotelNights: z.coerce.number().positive().int().optional().nullable(),
  hotelLocation: z.string().optional().nullable(),
  note: z.string().optional().nullable(),
});
export type BusinessTravelVersionInput = z.infer<
  typeof BusinessTravelVersionInputSchema
>;

export const BusinessTravelVersionUpdateSchema = makeUpdateSchema(
  BusinessTravelVersionInputSchema,
);
export type BusinessTravelVersionUpdate = z.infer<
  typeof BusinessTravelVersionUpdateSchema
>;
