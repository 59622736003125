import { Button } from '@carbonfact/ui-components/src/Button';
import classNames from 'classnames';
import { type ReactNode, useEffect } from 'react';

interface SideModalProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  subtitle?: ReactNode;
  actions?: ReactNode;
  allowClickAwayClosing?: boolean;
  className?: string;
  children?: ReactNode;
}

export default function SideModal({
  opened,
  onClose,
  title,
  subtitle,
  actions,
  allowClickAwayClosing = true,
  className,
  children,
}: SideModalProps) {
  useEffect(() => {
    // Lock page scrolling
    document.body.style.overflow = opened ? 'hidden' : '';

    return () => {
      // Make sure to unlock page scrolling on unmount!
      document.body.style.overflow = '';
    };
  }, [opened]);

  if (!opened) return null;

  return (
    <>
      <div
        className="block fixed z-40 right-0 left-0 top-0 bottom-0 bg-black bg-black/10 cursor-pointer"
        onClick={allowClickAwayClosing ? onClose : undefined}
      />
      <section
        className={classNames(
          'fixed top-0 right-0 w-[700px] max-w-[100%] h-full z-50 flex flex-col border-l border-gray-200 bg-white',
          className,
        )}
      >
        <div className="px-4 py-3 border-b border-gray-200 flex flex-row flex-wrap items-center justify-between">
          <div className="font-bold text-xl text-carbon-800">{title}</div>
          <div className="flex flex-row gap-2 items-center">
            {actions}
            <Button.Icon
              onClick={onClose}
              variant="invisible"
              className="w-[32px] h-[32px]"
              icon={{
                source: 'hero',
                name: 'XMarkIcon',
                type: 'line',
              }}
            />
          </div>
        </div>
        {subtitle && (
          <div className="w-full px-4 py-2 border-b border-gray-200 bg-gray-50 text-xs text-gray-500">
            {subtitle}
          </div>
        )}
        <div className="grow overflow-y-auto">{children}</div>
      </section>
    </>
  );
}
