import { XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import type { ReactNode } from 'react';

interface SideModalProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  actions?: ReactNode;
  allowClickAwayClosing?: boolean;
  className?: string;
  children?: ReactNode;
  scrollable?: boolean;
}

export default function Popup({
  opened,
  onClose,
  title,
  actions,
  allowClickAwayClosing = true,
  className,
  children,
  scrollable = true,
}: SideModalProps) {
  if (!opened) return null;

  return (
    <div className="fixed flex z-50 right-0 left-0 top-0 bottom-0 bg-black/30">
      <div
        className={classNames(
          'absolute   z-10',
          allowClickAwayClosing ? 'cursor-pointer' : '',
        )}
        onClick={allowClickAwayClosing ? onClose : undefined}
      />
      <article
        className={classNames(
          'z-20  m-auto flex flex-col rounded-xl bg-white max-w-[100vw]',
          className?.includes('w-') ? '' : 'min-w-[480px]',
          className?.includes('h-') ? '' : 'max-h-[100vh]',
          className,
        )}
      >
        <div className="pt-6 pb-2 px-6 border-b border-gray-200 flex flex-row items-center justify-between">
          <span className="font-bold text-lg text-carbon-800">{title}</span>
          <button type="button" onClick={onClose}>
            <XMarkIcon className="h-5 w-5 text-gray-600 cursor-pointer" />
          </button>
        </div>
        <div
          className={classNames(
            'p-6 flex flex-col gap-6',
            scrollable ? 'overflow-y-auto' : '',
          )}
        >
          {children}
        </div>
        {actions && (
          <div className="p-6 flex flex-row gap-3 items-center justify-end">
            {actions}
          </div>
        )}
      </article>
    </div>
  );
}
