import Title from 'app/components/Title';
import classNames from 'classnames';
import type { Route } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import type React from 'react';

interface ContainerProps {
  type: 'LG' | 'MD';
  title?: string;
  banner?: React.ReactNode;
  subTitle?: string;
  align?: 'left' | 'center' | 'right';
  children: React.ReactNode;
  className?: string;
  actions?: React.ReactNode;
  actionsPosition?: 'title' | 'backlink';
  backLink?: {
    href: Route | URL;
    label: string;
  };
}
export default function Container({
  type,
  banner,
  children,
  className,
  backLink,
  title,
  subTitle,
  actions,
  actionsPosition = 'title',
  align = 'left',
}: ContainerProps) {
  return (
    <div
      className={classNames(
        className,
        type === 'LG' && 'px-20 w-full',
        type === 'MD' && 'px-20 max-w-[1200px] box-content',
        'items-center flex-grow',
      )}
    >
      {banner}
      {(backLink || actionsPosition === 'backlink') && (
        <div className="flex flex-1 flex-row justify-between mt-4 items-center">
          {backLink ? (
            <Link href={backLink.href}>
              <Image
                src="/img/icons/back.svg"
                alt="back"
                width={20}
                height={20}
                className="mr-1 inline h-5 w-5 bg-carbon-500"
              />
              <span className="text-sm font-medium text-carbon-500">
                {backLink.label}
              </span>
            </Link>
          ) : (
            <div />
          )}
          <div className="flex flex-row gap-4 justify-self-end">
            {actionsPosition === 'backlink' && actions}
          </div>
        </div>
      )}
      {(title || subTitle) && (
        <div className="flex flex-row items-center justify-between my-8">
          <Title title={title || ''} align={align} subTitle={subTitle} />
          {actionsPosition === 'title' && actions}
        </div>
      )}
      <div className="pt-4 w-full">{children}</div>
    </div>
  );
}
