import { Button } from '@carbonfact/ui-components/src/Button';
import type { ButtonProps } from '@carbonfact/ui-components/src/Button/variants';
import Icon from '@carbonfact/ui-components/src/Icon';

type ExportButtonProps = {
  onClick: () => void;
  variant?: ButtonProps['variant'];
  label: string;
  className?: string;
};

export const UploadButton = ({
  onClick,
  label,
  variant,
  className,
}: ExportButtonProps) => {
  return (
    <Button.Default
      onClick={onClick}
      variant={variant ?? 'secondary'}
      className={className}
    >
      <Icon
        width={18}
        height={18}
        icon={{
          source: 'hero',
          name: 'ArrowUpTrayIcon',
          type: 'solid',
        }}
      />
      {label}
    </Button.Default>
  );
};
