import { z } from 'zod';
import { Climatiq } from '../../../../climatiq';
import {
  type UserEditableDataVersioningMetadata,
  makeUpdateSchema,
} from '../../user-editable-data';

export interface ExpenseVersion extends UserEditableDataVersioningMetadata {
  year: number;
  country: string;
  activityType: Climatiq.ActivityKey;
  amount: number;
  currency: string;

  note: string | null;

  ghgFootprintKgCo2e: number | null;
  sourceTrail: Climatiq.SourceTrail | null;
}

export const ExpenseVersionInputSchema = z.object({
  year: Climatiq.YearSchema,
  country: Climatiq.CountryCodeSchema,
  activityType: Climatiq.ActivityKeySchema,
  amount: z.coerce.number().positive(),
  currency: Climatiq.UnitSchemas.MoneySchema.shape.money_unit,
  note: z.string().optional().nullable(),
});
export type ExpenseVersionInput = z.infer<typeof ExpenseVersionInputSchema>;

export const ExpenseVersionUpdateSchema = makeUpdateSchema(
  ExpenseVersionInputSchema,
);
export type ExpenseVersionUpdate = z.infer<typeof ExpenseVersionUpdateSchema>;
